:root {
	--base-12: 0, 0, 0;
	--base-11: 51, 51, 51;
	--base-10: 68, 68, 68;
	--base-09: 102, 102, 102;
	--base-08: 136, 136, 136;
	--base-07: 176, 176, 176;
	--base-06: 198, 198, 198;
	--base-05: 220, 220, 220;
	--base-04: 241, 241, 241;
	--base-03: 245, 245, 245;
	--base-02: 253, 253, 253;
	--base-01: 255, 255, 255;
	--primary-accent-orange: 255, 153, 61;
	--primary-accent-orange-light: 255, 200, 148;
	--primary-accent-orange-dark: 232, 137, 54;
	--primary-accent-pink: 226, 101, 145;
	--primary-accent-pink-light: 255, 138, 179;
	--primary-accent-pink-dark: 226, 48, 111;
	--primary-accent-blue: 99, 155, 244;
	--primary-accent-blue-light: 151, 191, 255;
	--primary-accent-blue-dark: 58, 126, 233;
	--primary-accent-white: 255, 255, 255;
	--primary-accent-grey-light: 245, 245, 245;
	--primary-accent-grey-dark: 198, 198, 198;
	--primary-accent-smoke-light: 183, 184, 188;
	--social-media-dark-text: 255, 255, 255;
	--social-media-dark-text-bg: 52, 110, 241;
	--social-media-light-text-bg: 242, 243, 247;

	// Primary
	--primary: var(--primary-accent-orange);
	--primary-light: var(--primary-accent-orange-light);
	--primary-dark: var(--primary-accent-orange-dark);
	--secondary: var(--primary-accent-pink);
	--secondary-light: var(--primary-accent-pink-light);
	--secondary-dark: var(--primary-accent-pink-dark);
	--tertiary: var(--primary-accent-blue);
	--tertiary-light: var(--primary-accent-blue-light);
	--tertiary-dark: var(--primary-accent-blue-dark);

	// Extras
	--overlay: var(--base-11);

	// Font Colors
	--font-brand-01: var(--primary-accent-orange);
	--font-brand-02: var(--primary-accent-pink);
	--font-dark: var(--base-11);
	--font-light: var(--base-01);
	--font-supportive: 111, 111, 111;
	--font-disabled: var(--base-04);
	--brandContainerBackground: linear-gradient(278deg, rgba(0, 0, 0, 0%), rgba(237, 57, 84, 43%)),
		var(--primary-accent-blue);

	// System Colors
	--error: 233, 38, 54;
	--success: 0, 199, 106;
	--info: 0, 129, 235;
	--pending: 235, 152, 41;

	// Live Casino
	--active-players-color: 253, 253, 253;
	--full-room-color: 135, 136, 136;

	// --notification-text-color: defaults to rgba(var(--base-02), 1);

	// Progress Meter
	--risk-level-base: var(--base-04);
	--risk-level-0: var(--base-04);
	--risk-level-1: var(--success);
	--risk-level-2: var(--success);
	--risk-level-3: var(--pending);
	--risk-level-4: var(--error);

	// Type Vars
	--display: normal normal 600 7rem 'Quicksand', sans-serif;
	--display-2: normal normal 600 5rem 'Quicksand', sans-serif;
	--display-3: normal normal 600 3.6rem/1.222 'Quicksand', sans-serif;
	--h1: normal normal 700 2.8rem/1.285 'Quicksand', sans-serif;
	--h2: normal normal 700 2.2rem/1.272 'Quicksand', sans-serif;
	--h3: normal normal 700 1.9rem/1.263 'Quicksand', sans-serif;
	--h4-bold: normal normal 700 1.7rem/1.412 'Quicksand', sans-serif;
	--h4-regular: normal normal 600 1.7rem/1.412 'Quicksand', sans-serif;
	--heading-5: normal normal 600 1.6rem/1.4375 'Quicksand', sans-serif;
	--label-regular: normal normal 600 1.5rem/1.333 'Quicksand', sans-serif;
	--label-bold: normal normal 700 1.5rem/1.333 'Quicksand', sans-serif;
	--caption: normal normal 600 1.2rem/1.333 'Quicksand', sans-serif;
	--caption-bold: normal normal 700 1.2rem/1.335 'Quicksand', sans-serif;
	--small: normal normal 600 1.1rem/1.365 'Quicksand', sans-serif;
	--small-bold: normal normal 700 1.1rem/1.365 'Quicksand', sans-serif;

	// Font Changes 4
	// --top-right-corner-link: defaults to --label-regular;
	//--product-icon-text: defaults to --label-regular;
	--footer-links: var(--label-bold);
	//--footer-flag-language-text: defaults to --label-regular;
	--footer-link-continue-icon-color: rgba(var(--primary-accent-orange), 1);
	// Notification Inbox
	// Notification counter
	--unread-message-counter-bg-color: var(--primary-accent-blue);
	--unread-message-counter-text-color: var(--font-light);

	// Notification Header
	--my-notifications-header-bg-color: var(--primary-accent-white);
	--my-notifications-back-button-filter: var(--filter-primary);
	--my-notifications-heading-color: var(--font-dark);
	--my-notifications-mark-all-read-color: var(--primary-accent-pink);
	--my-notifications-back-button-color: rgba(var(--primary-accent-orange), 1);

	// Notification: Unread message
	--unread-message-notifier-color: var(--primary-accent-blue);
	--unread-message-title-color: var(--font-dark);
	--unread-message-subtitle-color: var(--font-supportive);
	--unread-message-date-color: var(--font-dark);

	// Notification: Read message
	--read-message-color: var(--font-supportive);
	--read-message-title-color: var(--font-supportive);

	// Notification: No Data
	--no-notifications-icon-color: var(--primary-accent-blue-light);
	--no-notifications-heading-color: var(--primary-accent-blue-dark);
	--no-notifications-subheading-color: var(--primary-accent-blue-light);

	// Notification: Full screen popup
	--notification-popup-bg: var(--base-01);
	--notification-popup-title: var(--font-dark);
	--notification-popup-subtitle: var(--font-dark);
	--notification-popup-close-icon: var(--primary-accent-orange);
	--notification-popup-button-bg: var(--primary-accent-orange);
	--notification-popup-button-text: var(--base-01);
	--notification-popup-blur-bg: var(--base-11);
	--notification-popup-dot-active: var(--primary-accent-orange);
	--notification-popup-dot: var(--primary-accent-orange-light);

	// Web-push
	--web-push-popup-bg: var(--base-01);
	--web-push-popup-title: var(--font-dark);
	--web-push-popup-subtitle: var(--font-dark);
	--web-push-popup-button-bg: var(--primary-accent-orange);
	--web-push-popup-button-text: var(--primary-accent-orange);

	// Source Of Wealth
	--source-of-wealth-help-text: var(--font-dark);
	--source-of-wealth-form-bg: var(--base-03);
	--source-of-wealth-form-text: var(--font-dark);
	--source-of-wealth-dropdown-bg: var(--primary-accent-white);
	--source-of-wealth-dropdown-border: var(--base-04);

	//embed link
	--embed-link-color: var(--primary-accent-pink);

	// Landing Page
	--landing-default-bg-color: rgba(var(--primary-accent-blue), 1);
	--login-btn-bg: rgba(var(--primary-accent-orange), 1);
	// --login-btn-text: defaults to --base-02
	--landing-page-logo: rgba(var(--base-01), 1);
	// --landing-page-title: defaults to --base-02
	--product-icon-bg: rgba(var(--base-01), 0.25);
	--product-icon: rgba(var(--base-01), 1);
	// --product-icon-label: defaults to --base-02
	--terms-and-condition_bg: rgba(var(--overlay), 0.8);
	--bonus-terms-text: rgba(var(--active-players-color), 1);
	--bonus-terms-link: rgba(var(--primary-accent-pink), 1);
	--welcome-popup-bg: rgba(var(--primary-accent-white), 1);
	--welcome-popup-background-circle-fill: rgba(215, 216, 220, 100%);
	--welcome-popup-progress-color: rgba(var(--font-dark), 1);
	--welcome-popup-text-color: rgba(var(--primary-accent-pink), 1);

	// Head
	--head-bg: rgba(var(--primary-accent-orange), 1);
	--page-head-logo: rgba(var(--base-01), 1);
	--page-head-logo-height: 24px;
	--page-head-title: rgba(var(--font-light), 1);
	// --account-text:  defaults to --base-02
	--account-icon: rgba(var(--primary-accent-orange), 1);
	--balance-circle: rgba(var(--base-04), 1);
	--balance-icon-wrapper: rgba(var(--primary-accent-orange), 1);
	--balance-icon-wrapper-basic: rgba(var(--primary-accent-white), 1);
	--balance-shop-animate-points-wrapper: rgba(var(--base-05), 1);
	--balance-text: rgba(var(--font-dark), 1);
	--balance-icons: rgba(var(--primary-accent-orange), 1);
	--page-head-link: rgba(var(--base-01), 1);
	--page-head-icon: rgba(var(--base-01), 1);
	--hamburger-menu: rgba(var(--base-01), 1);
	--balance-header-icon: rgba(var(--primary-accent-white), 1);
	--quick-deposit-icon-color: rgba(var(--base-01), 1);
	--balance-fill-icon-color: rgba(var(--primary-accent-orange), 1);

	// Chip
	--balance-chip-circle: rgba(var(--primary-accent-orange), 1);
	--balance-icon-chip-wrapper: rgba(var(--primary-accent-white), 1);
	--balance-chip-icon: rgba(var(--primary-accent-orange), 1);
	--balance-chip-text: rgba(var(--font-light), 1);

	// Regulations Header
	--reg-header-dga-background: var(--primary-accent-orange);
	--reg-header-dga-border: var(--base-01);
	--reg-header-dga-color: var(--base-01);
	--reg-header-ksa-background: var(--primary);
	--reg-header-ksa-color: rgba(var(--font-light), 1);
	--reg-header-ksa-border: var(--primary-accent-orange-dark);

	// Buttons
	--button-primary-bg: rgba(var(--primary-accent-orange), 1);
	--button-primary-bg-hover: rgba(var(--primary-accent-pink-light), 1);
	--button-primary-text: rgba(var(--font-light), 1);
	--button-secondary-bg: rgba(var(--primary-accent-white), 1);
	// --button-secondary-text: defaults to --base-02
	--button-wrapper-bg: rgba(var(--base-02), 1);
	// --button-wrapper-bg-light: defaults to --base-03
	--button-register: rgba(var(--primary-accent-white), 1);
	--button-register-text: rgba(var(--primary-accent-orange), 1);
	// --button-pag: defaults to --base-11
	--support-button: rgba(var(--primary-accent-orange), 1);
	--support-button-content: rgba(var(--base-01), 1);
	// --button-border-radius: defaults to 3px
	--button-disabled-bg: rgba(var(--base-04), 1);
	--button-disabled-text: rgba(var(--font-supportive), 1);
	--button-success-bg: rgba(var(--base-11), 0.25);
	--button-success-color: rgba(var(--base-02), 1);
	--button-primary-slider-bg-exeption: rgba(var(--primary-accent-grey-dark), 1);

	// Badges
	--badge-primary-bg: rgba(var(--primary-accent-pink), 1);
	--badge-primary-text: rgba(var(--base-02), 1);
	--badge-new-bg: rgba(var(--primary-accent-pink), 1);
	--badge-new-text: rgba(var(--base-02), 1);
	--badge-dark-bg: rgba(var(--base-12), 1);
	--badge-dark-text: rgba(var(--base-01), 1);
	--badge-secondary-bg: rgba(var(--primary-accent-blue), 1);

	// Select
	--select-border-bottom-color: rgba(var(--base-07), 1);
	--select-selected-option-color: rgba(var(--primary-accent-blue-dark), 1);
	--select-option-color: rgba(var(--base-11), 1);
	--select-option-bg-color: rgba(var(--base-01), 1);
	--select-legend-color: rgba(var(--primary-accent-blue-light), 1);
	--select-arrow-down-color: rgba(var(--primary-accent-orange), 1);

	// Link List
	--link-list: rgba(var(--primary-accent-grey-light), 1);
	--list-detail-value: rgba(var(--font-supportive));
	--list-text-color: rgba(var(--font-dark), 1);
	--list-item-value-color: rgba(152, 152, 156, 100%);

	// Borders
	--list-border-bottom: rgba(var(--primary-accent-grey-dark), 1);

	// Filters 12
	--filter-bg: rgba(var(--primary-accent-orange), 1);
	//--filter-border-primary: 1px solid rgba(var(--primary-accent-blue-dark), 1);;
	--filter-bg-secondary: rgba(var(--primary-accent-white), 1);
	// --filter-border-secondary: defaults to --base-09
	--filter-border-secondary: rgba(255, 255, 255, 100%);
	--filter-idle: rgba(var(--font-light), 1);
	--filter-idle-secondary: rgba(var(--base-02), 1);
	--filter-active: rgba(var(--font-light), 1);
	--filter-active-secondary: rgba(var(--primary-accent-pink), 1);
	--filter-bar-active: rgba(var(--primary-accent-white), 1);
	--filter-bar-active-secondary: rgba(var(--primary-accent-pink), 1);
	--filter-hover: rgba(var(--base-01), 0.6);
	--filter-hover-secondary: rgba(var(--base-01), 0.6);
	--filter-support-links: rgba(var(--font-light), 1);
	--filter-bar-bottom-border: rgba(var(--base-04), 1);
	--filter-active-bg: rgba(var(--primary-accent-white), 1);

	//Mobile search input field
	--search-bg: rgba(var(--primary-accent-white), 1);
	--search-text: rgba(var(--primary-accent-orange), 1);
	--search-icon: rgba(var(--primary-accent-orange), 1);
	--search-text-small: rgba(var(--primary-accent-orange), 1);
	--search-icon-bg-color: var(--submenu-item-bg);
	--search-cancel-font: var(--label-regular);
	--search-cancel-color: rgba(var(--primary-accent-white), 1);

	//Mobile search results
	--search-results-bg: rgba(var(--primary-accent-grey-light), 1);
	--search-result-item-bg: rgba(var(--primary-accent-white), 1);
	--search-results-caption: rgba(var(--font-dark), 1);
	--search-results-caption-font: var(--label-bold);
	--search-results-more: rgba(var(--primary-accent-pink), 1);
	--search-no-results-text-font: var(--caption);
	--search-no-results-text-color: rgba(var(--font-dark), 1);
	--search-ph-gradient-bottom: rgba(var(--base-07), 0.3);
	--search-ph-gradient-top: rgba(var(--base-07), 1);
	--search-ph-mask: rgba(var(--base-02), 1);

	//Desktop search input field
	--search-bg-large: rgba(var(--base-03), 1);
	--search-text-large: rgba(var(--primary-accent-orange), 1);
	--search-text-desktop-placeholder: rgba(var(--font-brand-01), 1);
	--search-icon-large: rgba(var(--primary-accent-orange), 1);

	//Desktop search results
	--search-no-results-desktop-text-font: var(--h3);
	--search-no-results-desktop-text-color: rgba(var(--font-dark), 1);

	// Custom checkboxes
	--custom-checkbox-border-color: var(--base-06);
	--custom-checked-checkbox-bg: var(--primary-accent-pink);
	--custom-checked-checkbox-border-color: var(--primary-accent-pink);
	//--custom-checked-checkmark-color: var(--base-12);
	--custom-checked-disabled-checkbox-bg: var(--base-07);
	--custom-checked-disabled-checkbox-border-color: var(--base-07);
	//--custom-checked-disabled-checkbox-checkmark: var(--base-12);

	// Custom radiobuttons
	--custom-checked-radio-bg: var(--primary-accent-pink);
	--custom-checked-radio-border-color: var(--primary-accent-pink);
	--custom-radio-border: var(--base-06);
	--custom-disabled-radio-border-color: var(--base-06);
	--custom-checked-disabled-radio-bg: var(--base-07);
	--custom-checked-disabled-radio-border-color: var(--base-07);

	// Section Head
	--main-icon: rgba(var(--base-11), 1);
	--main-title: rgba(var(--font-dark), 1);
	--main-subtitle: rgba(var(--font-supportive), 1);
	--section-head-main-icon: rgba(var(--base-11), 1);
	--deposit-confirmation-icon-color: rgba(var(--font-dark), 1);
	// --inverted-main-icon: defaults to --base-11
	// --inverted-main-title: defaults to --base-11
	// --inverted-main-subtitle: defaults to --base-11

	//MitID sidebar
	--sidebar-wrapper-bg: rgba(var(--base-01), 1);
	--sidebar-close-btn: rgba(var(--primary-accent-orange), 1);

	// Game Tags
	--hot-bg: rgba(var(--primary-accent-pink), 1);
	--new-bg: rgba(var(--primary-accent-blue), 1);
	--hot-text: rgba(var(--font-light), 1);
	--new-text: rgba(var(--font-light), 1);
	--game-tag-background-color--EXCLUSIVE: rgba(var(--secondary), 1);
	--game-tag-text-color--EXCLUSIVE: rgba(var(--primary-accent-grey-light), 1);
	--provider-name-line-height: 16px;
	--game-tag-line-height: 16px;
	--active-players-line-height: 15px;
	--stakes-pill-line-height: 16px;

	// Modal
	--modal-wrapper-bg: rgba(var(--primary-accent-white), 1);

	// Sign In
	--sign-in-box: rgba(var(--base-01), 1);
	--sign-in-box-border: transparent;

	// Tabs
	--product-tab: rgba(var(--base-01), 1);
	//--product-icon: rgba(var(--primary-accent-pink), 1);
	--product-tab-content: rgba(var(--font-supportive), 1);
	--product-tab-border-top: transparent;

	// Sidebar
	--sidebar-bg: rgba(var(--primary-accent-white), 1);
	--sidebar-box: rgba(var(--base-04), 1);
	--sidebar-shadow: rgba(var(--base-12), 0.2);
	// --sidebar-border-left: defaults to the same color as sidebar-bg

	// Payments
	--spinner-loading-animation: rgba(var(--primary-accent-pink), 1);

	// Footer
	--footer-icon: rgba(var(--base-07), 1);
	--footer-bg: rgba(var(--base-01), 1);
	--footer-box: rgba(var(--base-03), 1);
	--footer-text: var(--font-dark);
	--language-text: rgba(var(--font-dark), 1);
	--footer-payment-logo-bg: rgba(var(--primary-accent-grey-light), 1);
	--footer-payment-icon: rgba(var(--base-07), 1);
	--footer-social-icon: rgba(var(--base-06), 1);
	--footer-18-icon: rgba(var(--error), 1);
	--footer-18-icon-text: rgba(var(--font-light), 1);
	--footer-logo-18-height: 45px;
	--footer-logo-18-width: 45px;
	//--flag-eu: rgba(var(--primary-accent-blue), 1);
	--flag-eu-dots: rgba(var(--base-01), 1);
	--footer-link-color: rgba(var(--font-dark), 1);

	// Hero
	--hero-bg: rgba(var(--base-03), 1);
	//--hero-img: var(--brandContainerBackground);
	--hero-font-color: rgba(var(--font-dark), 1);
	--hero-bg-position: left top;
	--hero-bg-repeat: repeat;

	// SEO-content 3
	--seo-read-more-btn: var(--primary-accent-pink);
	//--seo-content-border-bottom: rgba(var(--primary-accent-blue-light), 1);
	--seo-content-bg: rgba(var(--base-03), 1);

	// Card
	--box-bg: rgba(var(--primary-accent-grey-light), 1);
	--browse-circle-bg: rgba(var(--primary-accent-grey-light), 1);
	// --browse-circle-icon: defaults to --primary-accent-blue
	--open-in-new-window-icon: var(--font-supportive);

	// Loader
	--loading-animation-dots-color: rgba(var(--primary-accent-orange), 1);

	// Bonus welcome package
	--bonus-welcome-package-item-bg: rgba(var(--primary-accent-grey-light), 1);
	--bonus-welcome-package-item-text-color: rgba(var(--font-dark), 1);
	--bonus-welcome-package-nested-collapse-bg: rgba(var(--primary-accent-beige-light), 1);
	--bonus-welcome-package-icon-color: rgba(var(--font-light), 1);
	--bonus-welcome-package-light-text-color: rgba(var(--font-light), 1);
	--bonus-welcome-package-item-read-more-button-box: rgba(var(--primary-accent-grey-light), 1);
	--bonus-detail-pill-bg: rgba(var(--primary-accent-orange), 1);

	// Active bonus
	--active-bonus-progress-lane: rgba(var(--primary-accent-grey-dark), 1);
	--active-bonus-progress-bar: rgba(var(--primary-accent-orange), 1);

	// Free to play
	--free-to-play-banner-color: rgba(var(--font-light), 1);
	--free-top-play-card-overlay: rgba(var(--opacity-overlay), 0.5);

	// Virtuals
	// --virtuals-root-bg-color: var(--base-09);

	// Highlight-games
	// --highlight-games-bg-primary: defaults to rgba(var(--base-10), 1);
	// --highlight-games-bg-secondary: defaults to rgba(var(--base-11), 1);
	// --highlight-games-bg-tertiary: defaults to rgba(var(--base-12), 1);
	// --highlight-games-text-primary: defaults to rgba(var(--base-02), 1);
	// --highlight-games-text-secondary: defaults to rgba(var(--base-06), 1);
	// --highlight-games-text-disabled: defaults to rgba(var(--base-06), 1);
	// --highlight-games-text-secondary-disabled: defaults to rgba(var(--base-07), 1);
	// --highlight-games-betslip-balance-wrapper: defaults to rgba(var(--base-11), 1);
	// --highlight-games-betslip-balance-icon-color: defaults to rgba(var(--base-02), 1);
	// --highlight-games-betslip-border-color: defaults to rgba(var(--base-09), 1);
	// --highlight-games-betslip-header-text-color: defaults to rgba(var(--highlight-games-text-primary), 1);
	// --highlight-games-video-player-background: defaults to rgba(var(--base-12));
	// --highlight-games-mobile-betslip-bg: defaults to var(--highlight-games-bg-tertiary);
	// --highlight-games-mute-icon-color: defaults to rgba(var(--base-01));
	// --highlight-games-pending-bet-color: defaults to rgba(var(--pending), 1);
	// --highlight-games-bet-body-bg-color: defaults to rgba(var(--base-11);
	// --highlight-games-bet-betline-bg-color: defaults to rgba(var(--base-10);
	// --highlight-games-void-bet-color:defaults to rgba(var(--base-02);
	//--highlight-games-clear-bets-btn-color: defaults to rgba(var(--base-10), 1);
	// --highlight-games-market-count-text-color: defaults to rgba(var(--base-04), 1);
	// --highlight-games-bet-tab-text-color: rgba(var(--base-06), 1);
	// --highlight-games-bet-header-bg: defaults to --highlight-games-bg-tertiary
	// --highlight-games-bet-tab-border-color: defaults to rgba(var(--primary-accent-blue), 1);
	// --highlight-games-week-event-border: defaults to 0;
	// --highlight-games-week-event-hover-border: defaults to 0;

	// Onboarding
	// --highlight-games-bg-secondary: defaults to rgba(var(--base-11), 1)
	// --onboarding-backdrop-bg: defaults to rgba(var(--base-11), 0.85);
	--onboarding-item-bg: var(--card-bg);

	// New Game Card
	--gameCardTitleDetailColor: var(--primary-accent-blue-dark);
	--gameCardTitleDetailFont: var(--label-bold);
	--gameCardJackpotBg: var(--button-primary-bg);
	--gameCardJackpotText: rgba(var(--font-light), 1);
	--gameCardJackpotFont: var(--label-bold);
	--gameCardJackpotFontSmall: var(--small-bold);
	--gameCardDetailBg: var(--base-02);
	--gameCardTitle: var(--font-dark);
	--gameCardTitleSmall: var(--font-supportive);
	--gameCardWinnerBg: var(--base-02);
	--gameCardDotBg: var(--primary-accent-orange);

	// Game Info
	--gameInfoBg: var(--base-02);
	--gameInfoTitleName: var(--font-dark);
	--gameInfoTitleProvider: var(--font-supportive);
	--gameInfoLoveButton: var(--primary-accent-grey-light);
	--gameInfoLoveIcon: var(--primary-accent-pink);
	--gameInfoDescText: var(--font-dark);
	--gameInfoButtonBorder: var(--primary-accent-orange);
	--gameInfoButtonFont: var(--h3);
	--gameInfoButtonText: var(--primary-accent-orange);
	--gameInfoDetailBg: var(--base-05);
	--gameInfoDetailTitle: var(--font-dark);
	--gameInfoDetailAmount: var(--font-supportive);
	--gameInfoPlayRealButtonBg: var(--primary-accent-orange);
	--gameInfoPlayRealButtonText: var(--font-light);
	--game-info-exit-icon-color: rgba(var(--primary-accent-orange), 1);
	--game-info-exit-bg-color: rgba(var(--overlay), 0.8);

	// Explore Category Carousel
	--category-arrow-icon-color: rgba(var(--primary-accent-pink), 1);
	--category-arrow-bg: var(--base-02);
	--category-title-color: var(--font-dark);
	--category-link-color: var(--font-brand-02);

	// Explore Category Page
	--category-page-bg: var(--primary-accent-white);
	--category-page-title-color: var(--font-dark);
	--category-exit-icon-color: rgba(var(--primary-accent-orange), 1);

	//Slider input
	--slider-empty-track: var(--base-04);
	--slider-thumb: rgba(var(--primary-accent-pink), 1);
	--slider-filled-track: rgba(var(--primary-accent-pink), 1);

	// Transparent bg
	--background-alpha: rgba(var(--base-11), 0.85);

	// Split view
	--background-split-view: rgba(var(--primary-accent-blue-dark), 1);

	// Lotteries
	--lotteries-cash-svg-background: rgba(var(--base-06), 1);
	--lotteries-cash-svg-fill: rgba(var(--base-02), 1);

	// propose-locale
	//--propose-locale-text: defaults to --base-02;

	// Support nav
	--opacity-overlay: var(--base-11);
	--propose-locale-head-bg: var(--primary-accent-white);
	--propose-locale-border: var(--primary-accent-grey-light);
	--propose-locale-text: var(--font-dark);

	// Skeleton placeholders
	--skeleton-placeholder-bg: rgba(var(--base-03), 1);
	--skeleton-placeholder-bg-gradient: linear-gradient(
		-90deg,
		var(--skeleton-placeholder-bg) 0%,
		rgba(var(--base-05), 1) 50%,
		var(--skeleton-placeholder-bg) 100%
	);

	// Selection button
	--selection-button-color: var(--sportsbook-bg-secondary);
	--selection-button-selection-text: rgba(var(--font-supportive), 1);
	--selection-button-color-alternative: var(--sportsbook-bg-secondary);
	--selection-button-odds-text: rgba(var(--sportsbook-text-main), 1);
	--selection-button-disabled-bg-color: rgba(var(--base-05), 1);
	--selection-button-active-color: rgba(var(--primary-accent-orange), 1);
	--selection-button-hover-bg-color: rgba(var(--primary-accent-orange), 1);
	--selection-button-active-hover-bg-color: rgba(var(--primary-accent-orange), 1);
	--selection-button-hover-text-color: rgba(var(--font-light), 1);
	--selection-button-active-text-color: rgba(var(--font-light), 1);
	--selection-button-disabled-text-color: var(--button-disabled-text);

	// StakeInput
	--stake-input-button-bg-color: rgba(var(--base-02), 1);
	--stake-input-button-disabled-color: rgba(var(--base-05), 1);
	--stake-input-bg-color: rgba(var(--base-04), 1);
	--stake-input-text-color: rgba(var(--base-10), 1);
	--stake-input-icon-color: rgba(var(--primary), 1);
	--stake-input-icon-disabled-color: rgba(var(--base-06), 1);

	// Shop / Promotions
	--shop-points-balance-bg: rgba(var(--base-03), 1);
	--shop-points-balance-button-bg: rgba(var(--primary-accent-orange), 1);
	--shop-points-balance-button-text: rgba(var(--primary-accent-white), 1);
	--shop-points-faq-button: rgba(var(--primary-accent-orange), 1);
	--shop-info-banner-bg: rgba(var(--primary-accent-grey-light), 1);
	--bonus-item-detail-bg: rgba(var(--primary-accent-white), 1);
	--bonus-item-foreground-text: rgba(var(--font-light), 1);

	// Self-exclusion page
	--self-exlusion-toggle-bg: rgba(var(--primary-accent-grey-light), 1);
	--self-exlusion-warning-icon-color: rgba(var(--primary-accent-orange), 1);
	--self-exlusion-switch-toggle-on-bg: rgba(var(--primary-accent-pink), 1);
	--self-exlusion-switch-toggle-on-knob-bg: rgba(var(--primary-accent-pink), 1);
	--self-exlusion-switch-toggle-off-bg: rgba(var(--primary-accent-grey-dark), 1);
	--self-exlusion-switch-toggle-off-knob-bg: rgba(var(--primary-accent-grey-dark), 1);

	// Filter pills
	--filter-pills-bg: rgba(var(--primary-accent-grey-light), 1);
	--filter-pills-bg-active: rgba(var(--primary-accent-orange), 1);
	--filter-pills-text-color-active: rgba(var(--font-light), 1);
	--filter-pills-text-color: rgba(var(--primary-accent-pink), 1);

	// Submenu items
	--submenu-bg--desktop: var(--filter-bg-secondary);
	--submenu-item-bg--desktop: rgba(var(--base-03), 1);
	--submenu-item-bg-active--desktop: rgba(var(--primary-accent-orange), 1);
	--submenu-item-text-color--desktop: rgba(var(--primary-accent-pink), 1);
	--submenu-item-text-color-active--desktop: rgba(var(--font-light), 1);
	--submenu-item-border-color--desktop: transparent;
	--submenu-item-border-color-active--desktop: transparent;
	--submenu-item-bg: rgba(var(--base-03), 1);
	--submenu-item-bg-active: rgba(var(--primary-accent-orange), 1);
	--submenu-item-text-color: rgba(var(--primary-accent-orange), 1);
	--submenu-item-text-color-active: rgba(var(--font-light), 1);
	--submenu-item-border-color: transparent;
	--submenu-item-border-color-active: rgba(var(--font-light), 1);

	// Stepper
	--stepper-active: rgba(var(--primary-accent-pink), 1);
	--stepper-active-text: rgba(var(--base-10), 1);
	--stepper-inactive: rgba(var(--base-07), 1);
	--stepper-inside-text: var(--font-dark);

	// Cookie Banner
	--cookie-policy-link: rgba(var(--primary-accent-pink), 1);
	--policy-version-text: rgba(var(--primary-accent-blue-dark), 1);
	--cookie-toggle-bg-on: rgba(var(--primary-accent-pink-light), 1);
	--cookie-toggle-bg-off: rgba(var(--base-05), 1);
	--cookie-toggle-knob-on: rgba(var(--primary-accent-pink), 1);
	--cookie-toggle-knob-off: rgba(var(--primary-accent-pink), 1);
	--cookiebanner-wrapper-bg: rgba(var(--active-players-color), 1);
	--cookie-policy-footer-bg: rgba(var(--active-players-color), 1);
	--cookie-policy-footer-wrap-divider: var(--primary-accent-grey-light);
	--cookie-footer-policy-text: rgba(var(--base-11), 1);
	--cookie-footer-wrap-big-bg: rgba(var(--base-01), 1);
	--footer-wrapper-big-divider: var(--primary-accent-grey-light);
	--cookie-footer-wrap-small-bg: rgba(var(--base-01), 1);
	--footer-wrapper-small-divider: var(--primary-accent-grey-light);
	--cookie-accept-all-text-big: rgba(var(--active-players-color), 1);
	--cookie-accept-all-text-small: rgba(var(--active-players-color), 1);
	--cookie-save-settings-btn-text: rgba(var(--primary-accent-pink), 1);
	--cookie-banner-text: rgba(var(--font-supportive), 1);
	--cookie-accept-settings-text: rgba(var(--active-players-color), 1);
	--cookie-accept-settings-small-text: rgba(var(--active-players-color), 1);
	--cookie-save-settings-btn-bg: transparent;
	--cookie-small-save-settings-btn-bg: transparent;
	--cookie-small-save-settings-btn-text: rgba(var(--primary-accent-pink), 1);
	--cookie-link-supportive: rgba(var(--font-supportive), 1);
	--cookie-banner-logo: var(--page-head-logo);

	// Sportsbook
	// StakeInput
	//--stake-input-button-bg-color: var(--button-primary-bg);
	//--stake-input-button-disabled-color: rgba(var(--base-10), 1);
	//--stake-input-bg-color: defaults to rgba(var(--base-12), 1);
	//--stake-input-text-color: defaults to rgba(var(--base-10), 1);
	//--stake-input-icon-disabled-color: rgba(var(--base-06), 1);

	// UI package variables
	// ====================
	--scroll-area-scroll-indicator-color: var(
		--base-01
	); // Intentionally set this as rgb because we need to be able to use different alpha values.

	--floating-element-bg-color: rgba(var(--base-03), 1);
	--floating-element-text-color: rgba(var(--base-10), 1);

	// Pill
	--pill-label-color: rgba(var(--primary-accent-pink), 1);
	--pill-checked-label-color: rgba(var(--font-light), 1);
	--pill-checked-bg: rgba(var(--primary-accent-orange), 1);
	--pill-bg: rgba(var(--base-03), 1);

	// Lists
	--list-item-background-color: rgba(var(--base-01), 1);

	// Date picker
	--date-picker-background: rgba(var(--primary-accent-grey-light), 1);
	--date-picker-outline: rgba(var(--primary-accent-orange-light), 1);
	--date-picker-selected: rgba(var(--primary), 1);
	--date-picker-text: rgba(var(--font-dark), 1);
	--date-picker-text-supportive: rgba(var(--font-supportive), 1);
	--date-picker-text-on-selected: rgba(var(--font-light), 1);

	// Cards

	--card-bg: rgba(var(--base-03), 1);
	--lc-live-casino-card-bg: rgba(var(--base-13), 0.85);
	--lc-card-font-size: 11px;
	// --card-header-caption-font: defaults to --caption;
	--card-header-caption-font-color: rgba(var(--font-supportive), 1);
	// --card-header-title-font: defaults to --label-bold;
	--card-header-title-font-color: var(--primary-accent-blue-dark); // defaults to nothing;
	// --card-media-pill-bg defaults to card-bg
	// --card-media-pill-font defaults to --caption
	// --card-media-pill-border-radius defautls 12px;
	// --card-media-icon-right-bg defaults to --primary-accent-pink;
	// --card-media-icon-right-fill defaults to --base-02;
	// --card-media-banner-text-bg defaults to --primary-accent-blue;
	// --card-media-banner-text defaults to --base-02;
	--card-body-text-font-color: var(--font-supportive);

	// Game card
	--bowtie-guy: rgba(var(--base-06), 1);

	// UI Button dots

	// --button-dot-active-bg: defaults to rgba(var(--primary-accent-pink), 1)
	--button-dot-bg: rgba(var(--primary-accent-grey-dark), 1);

	// Modal

	// --action-sheet-button-text-color: defaults to rgba(var(--base-02);
	// --action-sheet-button-bg: defaults to rgba(var(--base-08)

	// Carousel
	--carouselTitleFont: var(--h2);
	--carouselTitleFontLarge: var(--display-3);
	--carouselTitleColor: var(--base-02);
	--carouselDescFont: var(--label-regular);
	--carouselDescFontLarge: var(--h4-bold);
	--carouselDescColor: var(--base-02);
	--carouselDotBg: var(--base-04);
	--carouselDotActiveBg: var(--primary-accent-pink);
	--carouselArrow: var(--primary-accent-pink);
	--carouselArrowBg: rgba(var(--base-02), 1);

	//multiLayer Carousel
	--multiLayerCarouselTitleFont: var(--h3);
	--multiLayerCarouselTitleFontLarge: var(--display-2);
	--multiLayerCarouselDescFont: var(--caption);
	--multiLayerCarouselDescFontLarge: var(--h4-regular);
	--multiLayerCarouselButtonBgColor: rgba(var(--primary-accent-orange), 1);
	--multiLayerCarouselButtonColor: var(--base-02);
	--multiLayerCarouselButtonFont: var(--label-bold);
	--multiLayerCarouselButtonFontLarge: var(--h3);

	// Binary Question
	--binary-question-inner-background: rgba(var(--primary-accent-white), 1);
	--binary-question-divider-color: rgba(var(--primary-accent-grey-light), 1);

	// tournaments
	--tournaments-sidebar-background: rgba(var(--primary-accent-white), 1);
	--tournaments-sidebar-surface: rgba(var(--primary-accent-grey-light), 1);
	--tournaments-sidebar-divider-on-background: rgba(var(--primary-accent-grey-dark), 1);
	--tournaments-sidebar-divider-on-surface: rgba(var(--primary-accent-white), 1);
	--tournaments-text-color-overwrite: unset;
	--tournaments-text-on-tag: #fff;
	--tournaments-button-primary-bg: var(--button-primary-bg);
	--tournaments-rank-highlight: rgba(var(--primary-accent-pink), 1);
	--tournaments-rank-on-highlight: rgba(var(--font-light), 1);
	--tournaments-rank-avatar-on-background: rgba(var(--primary-accent-grey-light), 1);
	--tournaments-rank-avatar-on-surface: rgba(var(--primary-accent-white), 1);
	--tournaments-rank-avatar-on-highlight: rgba(var(--primary-accent-white), 1);
	--tournaments-rank-avatar-win: rgba(var(--primary-accent-pink), 1);
	--tournaments-rank-avatar-on-win: rgba(var(--font-light), 1);
	--tournaments-rank-divider-on-background: rgba(var(--primary-accent-grey-dark), 1);
	--tournaments-results-title: rgba(var(--font-dark), 1);
	--tournaments-results-trophy: rgba(var(--font-dark), 1);
	--tournaments-results-pill-icon: rgba(var(--font-dark), 1);
	--tournaments-list-header-button: rgba(var(--font-brand-02), 1);
	--tournaments-list-item-details-button-text: rgba(var(--font-supportive), 1);
	--tournaments-list-item-details-button-icon: var(--button-primary-bg);
	--tournaments-list-item-timer: rgba(var(--font-supportive), 1);
	--tournaments-modal-header-button-background: rgba(var(--font-dark), 0.15);
	--tournaments-details-caption-alt: rgba(var(--font-supportive), 1);
	--tournaments-details-tab-border: rgba(183, 184, 188, 100%);
	--tournaments-label-regular: normal normal 600 1.5rem/1.333 'Quicksand', sans-serif;
	--tournaments-label-bold: normal normal 700 1.5rem/1.333 'Quicksand', sans-serif;
	--tournaments-caption: normal normal 600 1.2rem/1.333 'Quicksand', sans-serif;
	--tournaments-caption-bold: normal normal 700 1.2rem/1.333 'Quicksand', sans-serif;
	--tournaments-small: normal normal 600 1.1rem/1.272 'Quicksand', sans-serif;
	--tournaments-small-bold: normal normal 700 1.1rem/1.272 'Quicksand', sans-serif;
	--tournaments-name-transform: none;

	// quick deposit mobile toolbar
	--quick-deposit--toolbar-background: rgba(var(--primary-accent-orange), 1);
	--quick-deposit--button-wrapper-background: var(--primary-accent-orange);
	--quick-deposit--button-wrapper-border: var(--base-04);
	--quick-deposit--text-color: var(--base-01);
	--quick-deposit--icon-container-color: var(--base-01);
	--quick-deposit-modal-bg: rgba(var(--primary-accent-white), 1);
	--quick-deposit-amount-input-bg: rgba(var(--primary-accent-grey-light), 1);
	--quick-deposit-methods-collapse-bg: rgba(var(--primary-accent-grey-light), 1);

	//quick-deposit-modal
	--qd-modal-contrast-text: var(--primary-accent-orange);
	--qd-modal-cancel-text: var(--font-dark);

	//new-quick-deposit-modal
	--new-qd--change-method-color: rgba(var(--primary-accent-orange), 1);
	--new-qd--amount-input-bg: rgba(245, 245, 245, 100%);
	--new-qd--amount-input-border-color: var(--base-06);
	--new-qd--amount-input-focus-border-color: rgba(var(--primary-accent-orange), 1);
	--new-qd--amount-input-focus-text-color: rgba(var(--primary-accent-orange), 1);
	--new-qd--amount-input-text-color: rgba(var(--font-dark), 1);
	--new-qd--back-button-icon-color: rgba(var(--base-01), 1);
	--new-qd--disabled-amount-input-bg: rgba(245, 245, 245, 100%);
	--new-qd--font-color: var(--font-dark);
	--new-qd--list-border-color: rgba(var(--primary-accent-smoke-light), 1);
	--new-qd--modal-header-close-icon-bg: rgba(51, 51, 51, 15%);
	--new-qd--modal-header-close-icon-color: rgba(var(--base-11), 1);

	//new-quick-deposit-toolbar
	--new-qd--text-color: rgba(var(--font-brand-01), 1);
	--new-qd--button-wrapper-background: rgba(var(--base-01), 1);
	--new-qd--icon-container-color: rgba(var(--primary-accent-orange), 1);

	// inhouse jackpot
	--inhouse-jackpot--btn-bg-color: var(--base-03);
	--inhouse-jackpot--btn-bg-color--opted-in: var(--base-03);
	--inhouse-jackpot--btn-bg-color--desktop: var(--base-03);
	--inhouse-jackpot--btn-border-color--opted-in: var(--base-03);
	--inhouse-jackpot--btn-border-color: var(--base-03);
	--inhouse-jackpot--btn-text-color: var(--font-dark);
	--inhouse-jackpot--btn-text-color--opted-in: var(--font-dark);
	--inhouse-jackpot--btn-text-color--desktop: var(--font-dark);
	--inhouse-jackpot--icon-container-bg-color--desktop: var(--base-01);
	--inhouse-jackpot--icon-container-bg-color--desktop--opted-in: var(--primary-accent-orange);
	--inhouse-jackpot--icon-container-color: var(--font-dark);
	--inhouse-jackpot--icon-container-color--desktop: var(--primary-accent-orange);
	--inhouse-jackpot--icon-container-color--opted-in: var(--primary-accent-orange);
	--inhouse-jackpot--icon-container-color--desktop--opted-in: var(--base-01);
	--inhouse-jackpot--modal-background-color: var(--base-01);
	--inhouse-jackpot--modal-header-close-icon-color: var(--base-01);
	--inhouse-jackpot--modal-header-close-icon-color--terms-activated: var(--primary-accent-orange);
	--inhouse-jackpot--modal-contribution-info-color: var(--base-01);
	--inhouse-jackpot--modal-content-title-color: var(--font-dark);
	--inhouse-jackpot--modal-content-description-color: var(--font-dark);
	--inhouse-jackpot--modal-content-show-terms-link-color: var(--primary-accent-orange);
	--inhouse-jackpot--modal-content-terms-description-color: var(--font-supportive);
	--inhouse-jackpot--modal-content-terms-close-color: var(--primary-accent-orange);
	--inhouse-jackpot--modal-content-terms-title-color: var(--font-dark);
	--inhouse-jackpot--modal-optin-button-font: var(--label-bold);
	--inhouse-jackpot--modal-optin-button-color: var(--base-01);
	--inhouse-jackpot--modal-optout-button-color: var(--primary);
	--inhouse-jackpot--modal-optout-button-border-color: var(--primary);
	--inhouse-jackpot--modal-tiers-text-color: var(--base-01);
	--inhouse-jackpot--win-modal-color: var(--base-01);
	--inhouse-jackpot--win-modal-border-color: var(--primary-accent-orange);
	--inhouse-jackpot--win-modal-close-button-font: var(--h3);
	--inhouse-jackpot--win-modal-close-button-color: var(--base-01);

	// Credit Card
	--credit-card: rgba(var(--primary-accent-grey-light), 1);
	--cc-indicator: rgba(var(--base-08), 1);
	--cc-indicator-active: rgba(var(--primary-accent-orange), 1);
	--cc-card-add-btn: rgba(var(--base-03), 1);
	--cc-card-add-btn-text: rgba(var(--primary-accent-orange), 1);

	//Campaign-registration
	--collapse-view-chevron: rgba(var(--primary-accent-orange), 1);

	// Limits
	--limits-notification-icon: var(--base-11);
	--opening-at-clock-icon: rgba(var(--base-11), 1);

	//notification-warning-banner
	--notif-banner-bg: rgba(var(--base-03), 1);
	--notif-banner-icon: rgba(var(--primary-accent-orange), 1);

	// Responsible Gaming
	--time-limit-reached-label-color: var(--font-supportive);
	--time-limit-reached-timer-color: var(--base-11);

	// New input colors
	--input-background-color: var(--base-03);
	--input-disabled-background-color: var(--primary-accent-grey-dark);
	--input-border-color: var(--base-06);
	--input-border-hover-color: var(--primary-accent-pink);
	--input-border-focus-color: var(--primary-accent-pink);
	--input-border-disabled-color: var(--font-dark);
	--input-border-error-color: var(--error);
	--input-placeholder-color: var(--font-supportive);
	--input-placeholder-disabled-color: var(--font-supportive);
	--input-font-color: var(--font-dark);
	--input-font-focus-color: var(--primary-accent-pink);
	--input-font-disabled-color: var(--font-supportive);
	--input-error-color: var(--error);
	--input-helper-text-color: var(--font-dark);
	--select-arrow-color: var(--primary-accent-orange);
	--toggle-group-selected-color: var(--primary-accent-pink);
	--toggle-group-color: var(--font-supportive);

	// Ingame Desktop view
	--ingame-jurisdiction-color: rgba(var(--font-light), 1);
	--ingame-nav-icons: rgba(var(--base-01), 1);

	// Social media
	--social-media-button-background: var(--social-media-dark-text-bg);
	--social-media-button-background-icon: var(--social-media-light-text-bg);
	--social-media-button-text: var(--social-media-dark-text);

	// Marketplace & Shop
	--marketplace-continue-btn-text-color: rgba(var(--primary-accent-pink), 1);
	--marketplace-success-text-color: rgba(var(--base-12), 1);
	--marketplace-success-balance-color: rgba(var(--base-07), 1);
	--marketplace-success-balance-sum-color: rgba(var(--base-12), 1);
	--marketplace-success-main-icon: var(--section-head-main-icon);
	--marketplace-success-close-icon-color: rgba(var(--primary-accent-orange), 1);
	--marketplace-progress-bg: rgba(var(--primary-accent-white), 1);
	--marketplace-progress-bar-bg: rgba(var(--primary-accent-pink), 1);
	--marketplace-points-bg: rgba(var(--primary-accent-orange), 1);
	--marketplace-points-text: rgba(var(--font-light), 1);
	--marketplace-sale-bg: rgba(var(--primary-accent-pink), 1);
	--marketplace-sale-text: rgba(var(--font-light), 1);
	--marketplace-sale-text-bg: rgba(var(--base-12), 0.1);
	--marketplace-info-text: rgba(var(--font-dark), 1);
	--marketplace-wagering-text: rgba(var(--font-light), 1);
	--marketplace-card-bg: rgba(var(--base-04, 1));
	--marketplace-specials-bg: rgba(var(--base-04, 1));
	--marketplace-specials-card-bg: rgba(var(--primary-accent-white, 1));
	--marketplace-chevron: rgba(var(--primary-accent-red, 1));
	--marketplace-chevron-bg: rgba(var(--primary-accent-white, 1));

	//Mentor UI Gaming Habits -- Playing Behavior box --
	--button-selection-days-select-color: rgba(var(--primary-accent-orange), 1);
	--button-selection-days-color: rgba(var(--base-04), 1);
	--your-behaviour-box-chart: rgba(var(--primary-accent-orange), 1);
	--comparable-players-box-chart: rgba(var(--primary-accent-grey-light), 1);
	--box-info-data-player: rgba(var(--base-04), 1);
	--playing-behaviour-description-text: rgba(var(--font-supportive), 1);
	--playing-behaviour-pills-text-selected: rgba(var(--base-01), 1);
	--playing-behaviour-pills-text: rgba(var(--primary-accent-pink), 1);
	--playing-behaviour-percentage-text: rgba(var(--base-01), 1);
	--playing-behaviour-readmore-btn-color: rgba(var(--primary-accent-pink), 1);
	--game-type-label-color: rgba(var(--font-dark), 1);
	--vertical-line-others: rgba(var(--primary-accent-pink));

	// WeSpin
	--basic-horizontal-card: rgba(var(--primary-accent-grey-light), 1);
	--basic-horizontal-card-button-wrapper-bg: var(--sidebar-bg);
	--basic-horizontal-card-button-wrapper-border: rgba(var(--primary-accent-orange), 1);
	--basic-horizontal-card-button-svg-fill: rgba(var(--primary-accent-orange), 1);
	--basic-horizontal-card-icon-fill: var(--primary-accent-orange);
	--we-spin-clip-card-bg: rgba(var(--base-04), 1);
	--we-spin-clip-card-text: rgba(var(--font-supportive), 1);
	--we-spin-clip-card-title: rgba(var(--font-supportive), 1);
	--we-spin-create-stream-card-text: rgba(var(--base-01), 1);
	--we-spin-create-stream-card-title: rgba(var(--base-01), 1);
	--we-spin-create-stream-success-title: rgba(var(--font-supportive), 1);
	--we-spin-create-stream-success-subtitle: rgba(var(--font-supportive), 1);
	--we-spin-dashboard-container-bg: rgba(var(--primary-accent-grey-light), 1);
	--we-spin-dashboard-event-detail-border-color: rgba(var(--primary-accent-grey-dark), 1);
	--we-spin-dashboard-event-detail-title: rgba(var(--font-dark), 1);
	--we-spin-dashboard-event-detail-text: rgba(var(--font-supportive), 1);
	--we-spin-dashboard-event-detail-view-button: var(--primary-accent-orange-light);
	// --we-spin-event-detail-view-starts-in: var(--font-supportive);
	--we-spin-dashboard-settings-link: rgba(var(--base-01), 1);
	--we-spin-dashboard-switch-description-color: rgba(var(--base-08), 1);
	--we-spin-dashboard-switch-label-disabled: rgba(var(--font-dark), 1);
	--we-spin-dashboard-switch-toggle-disabled-bg: rgba(var(--font-disabled), 1);
	--we-spin-dashboard-switch-toggle-disabled-knob-bg: rgba(var(--font-disabled), 1);
	--we-spin-dashboard-switch-toggle-off-bg: rgba(var(--primary-accent-grey-dark), 1);
	--we-spin-dashboard-switch-toggle-off-knob-bg: rgba(var(--primary-accent-grey-dark), 1);
	--we-spin-dashboard-switch-toggle-on-bg: rgba(var(--primary-accent-pink), 1);
	--we-spin-dashboard-switch-toggle-on-knob-bg: rgba(var(--primary-accent-pink), 1);
	--we-spin-game-room-buyin-disabled-bg: rgba(var(--base-04), 1);
	--we-spin-livestream-stats-bg: var(--sidebar-bg);
	--we-spin-next-session-bottom-text-color: rgba(var(--font-supportive), 1);
	--we-spin-next-session-top-text-color: rgba(var(--font-dark), 1);
	--we-spin-next-session-middle-text-color: rgba(var(--primary-accent-green), 1);
	--we-spin-gameroom-buyin-stream-bg-color: var(--sidebar-bg);
	--we-spin-preview-stream-bg-color: rgba(var(--primary-accent-grey-light), 1);
	--we-spin-search-bg: rgba(var(--primary-accent-grey-light), 1);
	--we-spin-search-text: rgba(var(--base-10), 1);
	--we-spin-success-subtitle: rgba(var(--base-10), 1);
	--we-spin-tutorial-card-bg-color: var(--base-01);
	--we-spin-tutorial-card-bg-gradient: none;
	// --we-spin-tutorial-gradient-start: 60%;

	// Transaction history

	// Result products
	--result-products-label-color: rgba(var(--font-dark), 1);

	// New change bank bottom popup
	--change-bank-account-popup-close-icon-fill: rgba(var(--base-11), 1);
	--change-bank-account-popup-close-icon-bg: rgba(var(--base-05), 1);
	--change-bank-account-popup-bg: rgba(var(--primary-accent-white), 1);
	--filter-pills-bg-th: var(--primary-accent-grey-dark);
	--filtering-pills-bg: var(--primary-accent-pink);
	--filtering-pills-disable: var(--primary-accent-grey-dark);
	--filtering-pills-hover: var(--primary-accent-pink-light);
	--filtering-pill-font-color: var(--font-light);

	// Game Info
	--game-info-background-color: rgba(var(--primary-accent-grey-light), 1);
	--game-info-button-background-color: rgba(var(--primary-accent-grey-light), 1);
	--game-info-card-color: rgba(var(--primary-accent-white), 1);
	--game-info-menu-background: rgba(var(--primary-accent-white), 1);
	--game-info-menu-icon-color: rgba(var(--secondary), 1);
	--game-info-volatility-icon-color: rgba(var(--primary), 1);
	--game-info-jackpot-amount-text-color: rgba(var(--font-dark), 1);
	--game-info-row-value-text-color: rgba(var(--font-dark), 1);
	--game-info-name-text-color: rgba(var(--font-light), 1);
	--game-info-provider-text-color: rgba(var(--font-light), 0.6);
	--game-info-collapse-header-text-color: rgba(var(--font-dark), 1);
	--game-info-play-button-background-color: rgba(var(--primary), 1);
	--game-info-play-button-color: rgba(var(--font-light), 1);

	// Transaction history notification
	--transaction-history-notification-background-color: rgba(var(--primary-accent-grey-light), 1);
	--transaction-history-notification-icon-color: var(--font-dark);

	// Communication popup
	--communication-popup-background-color: rgba(var(--primary-accent-white), 1);
	--communication-popup-title-color: rgba(var(--font-dark), 1);

	// Limits container Tabs
	--tabs-bg: var(--button-disabled-bg);
	--active-tab-bg: rgba(var(--base-01), 1);
}
