/*
Using formula solid
$primary: #FF8AB3;
$home: #FF993D;
$away: #E26591;
$cl: #000000;
$base: #F5F5F5;
*/
.sr-bb {
	background: #f5f5f5;
	font-family: Roboto, Noto, 'Helvetica Neue', Helvetica, Arial, sans-serif;
	text-align: left;
}

.sr-bb.sr-rtl {
	text-align: right;
}

.sr-bb .sr-bb {
	background: none;
}

.sr-bb .srt-base-1 {
	background-color: transparent;
	border-color: rgba(0, 0, 0, 12%);
	color: #000;
}

.sr-bb .srt-base-1-win {
	background-color: transparent;
	border-color: #ff993d;
	color: #ff993d;
}

.sr-bb .srt-base-1-draw {
	background-color: transparent;
	border-color: rgba(0, 0, 0, 40%);
	color: rgba(0, 0, 0, 40%);
}

.sr-bb .srt-base-1-lose {
	background-color: transparent;
	border-color: #e26591;
	color: #e26591;
}

.sr-bb .srt-base-1-is-active {
	background-color: rgba(255, 138, 179, 12%);
	border-color: rgba(0, 0, 0, 12%);
	color: #000;
}

.sr-bb .srt-base-1-is-active-2 {
	background-color: #ddd;
	border-color: rgba(0, 0, 0, 12%);
	color: #000;
}

.sr-bb .srt-base-1-is-hoverable:hover {
	background-color: rgba(255, 138, 179, 12%);
	border-color: rgba(0, 0, 0, 12%);
	color: #000;
}

.sr-bb .srt-base-1-primary {
	background-color: transparent;
	border-color: #ff8ab3;
	color: #ff8ab3;
}

.sr-bb .srt-base-1-home-1 {
	background-color: transparent;
	border-color: #ff993d;
	color: #ff993d;
}

.sr-bb .srt-base-1-away-1 {
	background-color: transparent;
	border-color: #e26591;
	color: #e26591;
}

.sr-bb .srt-base-1-home-2 {
	background-color: transparent;
	border-color: #945068;
	color: #ff993d;
}

.sr-bb .srt-base-1-away-2 {
	background-color: transparent;
	border-color: #945068;
	color: #e26591;
}

.sr-bb .srt-base-1-home-3 {
	background-color: transparent;
	border-color: rgba(0, 0, 0, 12%);
	color: #ff993d;
}

.sr-bb .srt-base-1-away-3 {
	background-color: transparent;
	border-color: rgba(0, 0, 0, 12%);
	color: #e26591;
}

.sr-bb .srt-base-1-home-4 {
	background-color: transparent;
	border-color: #fcb574;
	color: #fcb574;
}

.sr-bb .srt-base-1-away-4 {
	background-color: transparent;
	border-color: #e890af;
	color: #e890af;
}

.sr-bb .srt-base-1-home-5 {
	background-color: transparent;
	border-color: #a66328;
	color: #a66328;
}

.sr-bb .srt-base-1-away-5 {
	background-color: transparent;
	border-color: #93425e;
	color: #93425e;
}

.sr-bb .srt-base-1-background {
	background-color: whitesmoke;
	border-color: rgba(0, 0, 0, 12%);
	color: #000;
}

.sr-bb .srt-base-2 {
	background-color: whitesmoke;
	border-color: rgba(0, 0, 0, 12%);
	color: #000;
}

.sr-bb .srt-base-2-is-active {
	background-color: #f6e8ed;
	border-color: rgba(0, 0, 0, 12%);
	color: #000;
}

.sr-bb .srt-base-2-is-hoverable:hover {
	background-color: #f6e8ed;
	border-color: rgba(0, 0, 0, 12%);
	color: #000;
}

.sr-bb .srt-base-3 {
	background-color: #f6e8ed;
	border-color: rgba(0, 0, 0, 12%);
	color: #000;
}

.sr-bb .srt-base-3-is-active {
	background-color: #f7e3ea;
	border-color: rgba(0, 0, 0, 12%);
	color: #000;
}

.sr-bb .srt-base-3-is-hoverable:hover {
	background-color: #f7e3ea;
	border-color: rgba(0, 0, 0, 12%);
	color: #000;
}

.sr-bb .srt-base-3-background {
	background-color: whitesmoke;
	border-color: rgba(0, 0, 0, 12%);
	color: #000;
}

.sr-bb .srt-base-4 {
	background-color: #f6f0f2;
	border-color: rgba(0, 0, 0, 12%);
	color: #000;
}

.sr-bb .srt-base-5-is-active {
	background-color: #f8d9e4;
	border-color: rgba(0, 0, 0, 12%);
	color: #000;
}

.sr-bb .srt-base-5-is-hoverable:hover {
	background-color: #f8d9e4;
	border-color: rgba(0, 0, 0, 12%);
	color: #000;
}

.sr-bb .srt-base-6 {
	background-color: transparent;
	border-color: rgba(0, 0, 0, 30%);
	color: #000;
}

.sr-bb .srt-primary-1 {
	background-color: #ff8ab3;
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-primary-1-is-active {
	background-color: #e67ca1;
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-primary-1-is-hoverable:hover {
	background-color: #e67ca1;
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-primary-1-is-disabled {
	background-color: #cc6e8f;
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-primary-2 {
	background-color: #b3617d;
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-primary-3 {
	background-color: #e67ca1;
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-primary-4 {
	background-color: #fbb5cd;
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-primary-5 {
	background-color: #fbb5cd;
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-primary-5-is-hoverable:hover {
	background-color: #ff8ab3;
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-primary-6 {
	background-color: #945068;
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-primary-7 {
	background-color: #ff8ab3;
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-primary-8 {
	background-color: #ff8ab3;
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-primary-8-is-active-1 {
	background-color: #e67ca1;
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-primary-8-is-active-2 {
	background-color: #e67ca1;
	border-color: #f5f5f5;
	color: #000;
}

.sr-bb .srt-primary-9 {
	background-color: #f8d5e1;
	border-color: rgba(0, 0, 0, 12%);
	color: #000;
}

.sr-bb .srt-primary-10 {
	background-color: #fd9fc0;
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-primary-11 {
	background-color: #e67ca1;
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-primary-12 {
	background-color: #ff8ab3;
	border-color: #000;
	color: #000;
}

.sr-bb .srt-primary-13 {
	background-color: #f6f0f2;
	border-color: rgba(255, 138, 179, 30%);
	color: #000;
}

.sr-bb .srt-base-1-primary-1 {
	background-color: transparent;
	border-color: #ff8ab3;
	color: #ff8ab3;
}

.sr-bb .srt-base-1-primary-2 {
	background-color: transparent;
	border-color: #b3617d;
	color: #b3617d;
}

.sr-bb .srt-base-1-primary-3 {
	background-color: transparent;
	border-color: #e67ca1;
	color: #e67ca1;
}

.sr-bb .srt-base-1-primary-4 {
	background-color: transparent;
	border-color: #fbb5cd;
	color: #fbb5cd;
}

.sr-bb .srt-base-1-primary-5 {
	background-color: transparent;
	border-color: #fbb5cd;
	color: #fbb5cd;
}

.sr-bb .srt-base-1-primary-6 {
	background-color: transparent;
	border-color: #945068;
	color: #945068;
}

.sr-bb .srt-base-1-primary-7 {
	background-color: transparent;
	border-color: #ff8ab3;
	color: #ff8ab3;
}

.sr-bb .srt-base-1-primary-8 {
	background-color: transparent;
	border-color: #ff8ab3;
	color: #ff8ab3;
}

.sr-bb .srt-base-1-primary-9 {
	background-color: transparent;
	border-color: #f8d5e1;
	color: #f8d5e1;
}

.sr-bb .srt-base-1-primary-10 {
	background-color: transparent;
	border-color: #fd9fc0;
	color: #fd9fc0;
}

.sr-bb .srt-base-1-primary-11 {
	background-color: transparent;
	border-color: #e67ca1;
	color: #e67ca1;
}

.sr-bb .srt-base-1-primary-13 {
	background-color: #f5f5f5;
	border-color: rgba(255, 138, 179, 30%);
	color: #f6f0f2;
}

.sr-bb .srt-base-1-neutral-1 {
	background-color: transparent;
	border-color: #d5d5d5;
	color: #d5d5d5;
}

.sr-bb .srt-base-1-neutral-2 {
	background-color: transparent;
	border-color: #191919;
	color: #191919;
}

.sr-bb .srt-base-1-neutral-3 {
	background-color: transparent;
	border-color: rgba(0, 0, 0, 12%);
	color: rgba(0, 0, 0, 12%);
}

.sr-bb .srt-base-1-neutral-4 {
	background-color: transparent;
	border-color: #565656;
	color: #565656;
}

.sr-bb .srt-base-1-neutral-5 {
	background-color: transparent;
	border-color: #3d3d3d;
	color: #3d3d3d;
}

.sr-bb .srt-base-1-neutral-6 {
	background-color: transparent;
	border-color: #bababa;
	color: #bababa;
}

.sr-bb .srt-base-1-neutral-7 {
	background-color: transparent;
	border-color: #565656;
	color: #565656;
}

.sr-bb .srt-base-1-neutral-8 {
	background-color: transparent;
	border-color: #d5d5d5;
	color: #d5d5d5;
}

.sr-bb .srt-base-1-neutral-9 {
	background-color: transparent;
	border-color: #e6e6e6;
	color: #e6e6e6;
}

.sr-bb .srt-base-1-neutral-10 {
	background-color: transparent;
	border-color: #d8d8d8;
	color: #d8d8d8;
}

.sr-bb .srt-base-1-neutral-11 {
	background-color: transparent;
	border-color: #939393;
	color: #939393;
}

.sr-bb .srt-base-1-neutral-12 {
	background-color: transparent;
	border-color: #4a4a4a;
	color: #4a4a4a;
}

.sr-bb .srt-base-1-neutral-13 {
	background-color: transparent;
	border-color: #ddd;
	color: #ddd;
}

.sr-bb .srt-base-1-is-active-primary {
	background-color: rgba(255, 138, 179, 12%);
	border-color: #ff8ab3;
	color: #ff8ab3;
}

.sr-bb .srt-base-1-is-active-home-1 {
	background-color: rgba(255, 138, 179, 12%);
	border-color: #ff993d;
	color: #ff993d;
}

.sr-bb .srt-base-1-is-active-away-1 {
	background-color: rgba(255, 138, 179, 12%);
	border-color: #e26591;
	color: #e26591;
}

.sr-bb .srt-base-1-is-active-home-2 {
	background-color: rgba(255, 138, 179, 12%);
	border-color: #945068;
	color: #ff993d;
}

.sr-bb .srt-base-1-is-active-away-2 {
	background-color: rgba(255, 138, 179, 12%);
	border-color: #945068;
	color: #e26591;
}

.sr-bb .srt-base-1-is-active-home-3 {
	background-color: rgba(255, 138, 179, 12%);
	border-color: rgba(0, 0, 0, 12%);
	color: #ff993d;
}

.sr-bb .srt-base-1-is-active-away-3 {
	background-color: rgba(255, 138, 179, 12%);
	border-color: rgba(0, 0, 0, 12%);
	color: #e26591;
}

.sr-bb .srt-base-1-is-active-home-4 {
	background-color: rgba(255, 138, 179, 12%);
	border-color: #fcb574;
	color: #fcb574;
}

.sr-bb .srt-base-1-is-active-away-4 {
	background-color: rgba(255, 138, 179, 12%);
	border-color: #e890af;
	color: #e890af;
}

.sr-bb .srt-base-1-is-active-home-5 {
	background-color: rgba(255, 138, 179, 12%);
	border-color: #a66328;
	color: #a66328;
}

.sr-bb .srt-base-1-is-active-away-5 {
	background-color: rgba(255, 138, 179, 12%);
	border-color: #93425e;
	color: #93425e;
}

.sr-bb .srt-base-1-is-active-primary-1 {
	background-color: rgba(255, 138, 179, 12%);
	border-color: #ff8ab3;
	color: #ff8ab3;
}

.sr-bb .srt-base-1-is-active-primary-2 {
	background-color: rgba(255, 138, 179, 12%);
	border-color: #b3617d;
	color: #b3617d;
}

.sr-bb .srt-base-1-is-active-primary-3 {
	background-color: rgba(255, 138, 179, 12%);
	border-color: #e67ca1;
	color: #e67ca1;
}

.sr-bb .srt-base-1-is-active-primary-4 {
	background-color: rgba(255, 138, 179, 12%);
	border-color: #fbb5cd;
	color: #fbb5cd;
}

.sr-bb .srt-base-1-is-active-primary-5 {
	background-color: rgba(255, 138, 179, 12%);
	border-color: #fbb5cd;
	color: #fbb5cd;
}

.sr-bb .srt-base-1-is-active-primary-6 {
	background-color: rgba(255, 138, 179, 12%);
	border-color: #945068;
	color: #945068;
}

.sr-bb .srt-base-1-is-active-primary-7 {
	background-color: rgba(255, 138, 179, 12%);
	border-color: #ff8ab3;
	color: #ff8ab3;
}

.sr-bb .srt-base-1-is-active-primary-8 {
	background-color: rgba(255, 138, 179, 12%);
	border-color: #ff8ab3;
	color: #ff8ab3;
}

.sr-bb .srt-base-1-is-active-primary-9 {
	background-color: rgba(255, 138, 179, 12%);
	border-color: #f8d5e1;
	color: #f8d5e1;
}

.sr-bb .srt-base-1-is-active-primary-10 {
	background-color: rgba(255, 138, 179, 12%);
	border-color: #fd9fc0;
	color: #fd9fc0;
}

.sr-bb .srt-base-1-is-active-primary-11 {
	background-color: rgba(255, 138, 179, 12%);
	border-color: #e67ca1;
	color: #e67ca1;
}

.sr-bb .srt-base-1-is-active-neutral-1 {
	background-color: rgba(255, 138, 179, 12%);
	border-color: #d5d5d5;
	color: #d5d5d5;
}

.sr-bb .srt-base-1-is-active-neutral-2 {
	background-color: rgba(255, 138, 179, 12%);
	border-color: #191919;
	color: #191919;
}

.sr-bb .srt-base-1-is-active-neutral-3 {
	background-color: rgba(255, 138, 179, 12%);
	border-color: rgba(0, 0, 0, 12%);
	color: rgba(0, 0, 0, 12%);
}

.sr-bb .srt-base-1-is-active-neutral-4 {
	background-color: rgba(255, 138, 179, 12%);
	border-color: #565656;
	color: #565656;
}

.sr-bb .srt-base-1-is-active-neutral-5 {
	background-color: rgba(255, 138, 179, 12%);
	border-color: #3d3d3d;
	color: #3d3d3d;
}

.sr-bb .srt-base-1-is-active-neutral-6 {
	background-color: rgba(255, 138, 179, 12%);
	border-color: #bababa;
	color: #bababa;
}

.sr-bb .srt-base-1-is-active-neutral-7 {
	background-color: rgba(255, 138, 179, 12%);
	border-color: #565656;
	color: #565656;
}

.sr-bb .srt-base-1-is-active-neutral-8 {
	background-color: rgba(255, 138, 179, 12%);
	border-color: #d5d5d5;
	color: #d5d5d5;
}

.sr-bb .srt-base-1-is-active-neutral-9 {
	background-color: rgba(255, 138, 179, 12%);
	border-color: #e6e6e6;
	color: #e6e6e6;
}

.sr-bb .srt-base-1-is-active-neutral-10 {
	background-color: rgba(255, 138, 179, 12%);
	border-color: #d8d8d8;
	color: #d8d8d8;
}

.sr-bb .srt-base-1-is-active-neutral-11 {
	background-color: rgba(255, 138, 179, 12%);
	border-color: #939393;
	color: #939393;
}

.sr-bb .srt-base-1-is-active-neutral-12 {
	background-color: rgba(255, 138, 179, 12%);
	border-color: #4a4a4a;
	color: #4a4a4a;
}

.sr-bb .srt-base-1-is-active-neutral-13 {
	background-color: rgba(255, 138, 179, 12%);
	border-color: #ddd;
	color: #ddd;
}

.sr-bb .srt-home-1 {
	background-color: #ff993d;
	border-color: #ff993d;
	color: #000;
}

.sr-bb .srt-away-1 {
	background-color: #e26591;
	border-color: #e26591;
	color: #000;
}

.sr-bb .srt-home-2 {
	background-color: #ff993d;
	border-color: #945068;
	color: #000;
}

.sr-bb .srt-away-2 {
	background-color: #e26591;
	border-color: #945068;
	color: #000;
}

.sr-bb .srt-home-3 {
	background-color: #ff993d;
	border-color: #f5f5f5;
	color: #000;
}

.sr-bb .srt-away-3 {
	background-color: #e26591;
	border-color: #f5f5f5;
	color: #000;
}

.sr-bb .srt-home-4 {
	background-color: #fcb574;
	border-color: #fcb574;
	color: #000;
}

.sr-bb .srt-away-4 {
	background-color: #e890af;
	border-color: #e890af;
	color: #000;
}

.sr-bb .srt-home-5 {
	background-color: #a66328;
	border-color: #a66328;
	color: #000;
}

.sr-bb .srt-away-5 {
	background-color: #93425e;
	border-color: #93425e;
	color: #000;
}

.sr-bb .srt-home-6 {
	background-color: rgba(255, 153, 61, 20%);
	border-color: rgba(0, 0, 0, 12%);
	color: #000;
}

.sr-bb .srt-away-6 {
	background-color: rgba(226, 101, 145, 20%);
	border-color: rgba(0, 0, 0, 12%);
	color: #000;
}

.sr-bb .srt-home-6-is-hoverable:hover {
	background-color: rgba(255, 153, 61, 20%);
	border-color: rgba(0, 0, 0, 12%);
	color: #000;
}

.sr-bb .srt-away-6-is-hoverable:hover {
	background-color: rgba(226, 101, 145, 20%);
	border-color: rgba(0, 0, 0, 12%);
	color: #000;
}

.sr-bb .srt-neutral-1 {
	background-color: #d5d5d5;
	border-color: rgba(0, 0, 0, 12%);
	color: #000;
}

.sr-bb .srt-neutral-2 {
	background-color: #191919;
	border-color: rgba(0, 0, 0, 16%);
	color: #fff;
}

.sr-bb .srt-neutral-3 {
	background-color: rgba(0, 0, 0, 12%);
	border-color: rgba(0, 0, 0, 12%);
	color: #000;
}

.sr-bb .srt-neutral-4 {
	background-color: #565656;
	border-color: rgba(255, 255, 255, 16%);
	color: #fff;
}

.sr-bb .srt-neutral-5 {
	background-color: #3d3d3d;
	border-color: rgba(255, 255, 255, 16%);
	color: #fff;
}

.sr-bb .srt-neutral-6 {
	background-color: #bababa;
	border-color: rgba(0, 0, 0, 12%);
	color: #000;
}

.sr-bb .srt-neutral-7 {
	background-color: #565656;
	border-color: rgba(255, 255, 255, 16%);
	color: #fff;
}

.sr-bb .srt-neutral-8 {
	background-color: #d5d5d5;
	border-color: rgba(0, 0, 0, 12%);
	color: #000;
}

.sr-bb .srt-neutral-9 {
	background-color: #e6e6e6;
	border-color: rgba(0, 0, 0, 12%);
	color: #000;
}

.sr-bb .srt-neutral-10 {
	background-color: #d8d8d8;
	border-color: rgba(0, 0, 0, 12%);
	color: rgba(0, 0, 0, 82%);
}

.sr-bb .srt-neutral-11 {
	background-color: #939393;
	border-color: rgba(0, 0, 0, 12%);
	color: #000;
}

.sr-bb .srt-neutral-12 {
	background-color: #4a4a4a;
	border-color: rgba(255, 255, 255, 16%);
	color: #fff;
}

.sr-bb .srt-neutral-13 {
	background-color: #ddd;
	border-color: rgba(0, 0, 0, 12%);
	color: #000;
}

.sr-bb .srt-win {
	background-color: #ff993d;
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-draw {
	background-color: rgba(0, 0, 0, 40%);
	border-color: rgba(0, 0, 0, 16%);
	color: #fff;
}

.sr-bb .srt-lose {
	background-color: #e26591;
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-text-secondary {
	-webkit-font-smoothing: antialiased;
	opacity: 0.7;
}

.sr-bb .srt-text-disabled {
	opacity: 0.35;
}

.sr-bb .srt-text-tertiary {
	opacity: 0.54;
}

.sr-bb .srt-primary-1 .srt-text-secondary,
.sr-bb .srt-primary-1.srt-text-secondary,
.sr-bb .srt-primary-1-is-active .srt-text-secondary,
.sr-bb .srt-primary-1-is-active.srt-text-secondary,
.sr-bb .srt-primary-1-is-hoverable:hover .srt-text-secondary,
.sr-bb .srt-primary-1-is-hoverable:hover.srt-text-secondary,
.sr-bb .srt-primary-1-is-disabled .srt-text-secondary,
.sr-bb .srt-primary-1-is-disabled.srt-text-secondary,
.sr-bb .srt-primary-2 .srt-text-secondary,
.sr-bb .srt-primary-2.srt-text-secondary,
.sr-bb .srt-primary-3 .srt-text-secondary,
.sr-bb .srt-primary-3.srt-text-secondary,
.sr-bb .srt-primary-4 .srt-text-secondary,
.sr-bb .srt-primary-4.srt-text-secondary,
.sr-bb .srt-primary-5 .srt-text-secondary,
.sr-bb .srt-primary-5.srt-text-secondary,
.sr-bb .srt-primary-5-is-hoverable:hover .srt-text-secondary,
.sr-bb .srt-primary-5-is-hoverable:hover.srt-text-secondary,
.sr-bb .srt-primary-6 .srt-text-secondary,
.sr-bb .srt-primary-6.srt-text-secondary,
.sr-bb .srt-primary-7 .srt-text-secondary,
.sr-bb .srt-primary-7.srt-text-secondary,
.sr-bb .srt-primary-8 .srt-text-secondary,
.sr-bb .srt-primary-8.srt-text-secondary,
.sr-bb .srt-primary-8-is-active-1 .srt-text-secondary,
.sr-bb .srt-primary-8-is-active-1.srt-text-secondary,
.sr-bb .srt-primary-8-is-active-2 .srt-text-secondary,
.sr-bb .srt-primary-8-is-active-2.srt-text-secondary,
.sr-bb .srt-primary-9 .srt-text-secondary,
.sr-bb .srt-primary-9.srt-text-secondary,
.sr-bb .srt-primary-10 .srt-text-secondary,
.sr-bb .srt-primary-10.srt-text-secondary,
.sr-bb .srt-primary-11 .srt-text-secondary,
.sr-bb .srt-primary-11.srt-text-secondary,
.sr-bb .srt-primary-12 .srt-text-secondary,
.sr-bb .srt-primary-12.srt-text-secondary,
.sr-bb .srt-primary-13 .srt-text-secondary,
.sr-bb .srt-primary-13.srt-text-secondary {
	-webkit-font-smoothing: antialiased;
	opacity: 0.7;
}

.sr-bb .srt-primary-1 .srt-text-disabled,
.sr-bb .srt-primary-1.srt-text-disabled,
.sr-bb .srt-primary-1-is-active .srt-text-disabled,
.sr-bb .srt-primary-1-is-active.srt-text-disabled,
.sr-bb .srt-primary-1-is-hoverable:hover .srt-text-disabled,
.sr-bb .srt-primary-1-is-hoverable:hover.srt-text-disabled,
.sr-bb .srt-primary-1-is-disabled .srt-text-disabled,
.sr-bb .srt-primary-1-is-disabled.srt-text-disabled,
.sr-bb .srt-primary-2 .srt-text-disabled,
.sr-bb .srt-primary-2.srt-text-disabled,
.sr-bb .srt-primary-3 .srt-text-disabled,
.sr-bb .srt-primary-3.srt-text-disabled,
.sr-bb .srt-primary-4 .srt-text-disabled,
.sr-bb .srt-primary-4.srt-text-disabled,
.sr-bb .srt-primary-5 .srt-text-disabled,
.sr-bb .srt-primary-5.srt-text-disabled,
.sr-bb .srt-primary-5-is-hoverable:hover .srt-text-disabled,
.sr-bb .srt-primary-5-is-hoverable:hover.srt-text-disabled,
.sr-bb .srt-primary-6 .srt-text-disabled,
.sr-bb .srt-primary-6.srt-text-disabled,
.sr-bb .srt-primary-7 .srt-text-disabled,
.sr-bb .srt-primary-7.srt-text-disabled,
.sr-bb .srt-primary-8 .srt-text-disabled,
.sr-bb .srt-primary-8.srt-text-disabled,
.sr-bb .srt-primary-8-is-active-1 .srt-text-disabled,
.sr-bb .srt-primary-8-is-active-1.srt-text-disabled,
.sr-bb .srt-primary-8-is-active-2 .srt-text-disabled,
.sr-bb .srt-primary-8-is-active-2.srt-text-disabled,
.sr-bb .srt-primary-9 .srt-text-disabled,
.sr-bb .srt-primary-9.srt-text-disabled,
.sr-bb .srt-primary-10 .srt-text-disabled,
.sr-bb .srt-primary-10.srt-text-disabled,
.sr-bb .srt-primary-11 .srt-text-disabled,
.sr-bb .srt-primary-11.srt-text-disabled,
.sr-bb .srt-primary-12 .srt-text-disabled,
.sr-bb .srt-primary-12.srt-text-disabled,
.sr-bb .srt-primary-13 .srt-text-disabled,
.sr-bb .srt-primary-13.srt-text-disabled {
	opacity: 0.35;
}

.sr-bb .srt-primary-1 .srt-text-tertiary,
.sr-bb .srt-primary-1.srt-text-tertiary,
.sr-bb .srt-primary-1-is-active .srt-text-tertiary,
.sr-bb .srt-primary-1-is-active.srt-text-tertiary,
.sr-bb .srt-primary-1-is-hoverable:hover .srt-text-tertiary,
.sr-bb .srt-primary-1-is-hoverable:hover.srt-text-tertiary,
.sr-bb .srt-primary-1-is-disabled .srt-text-tertiary,
.sr-bb .srt-primary-1-is-disabled.srt-text-tertiary,
.sr-bb .srt-primary-2 .srt-text-tertiary,
.sr-bb .srt-primary-2.srt-text-tertiary,
.sr-bb .srt-primary-3 .srt-text-tertiary,
.sr-bb .srt-primary-3.srt-text-tertiary,
.sr-bb .srt-primary-4 .srt-text-tertiary,
.sr-bb .srt-primary-4.srt-text-tertiary,
.sr-bb .srt-primary-5 .srt-text-tertiary,
.sr-bb .srt-primary-5.srt-text-tertiary,
.sr-bb .srt-primary-5-is-hoverable:hover .srt-text-tertiary,
.sr-bb .srt-primary-5-is-hoverable:hover.srt-text-tertiary,
.sr-bb .srt-primary-6 .srt-text-tertiary,
.sr-bb .srt-primary-6.srt-text-tertiary,
.sr-bb .srt-primary-7 .srt-text-tertiary,
.sr-bb .srt-primary-7.srt-text-tertiary,
.sr-bb .srt-primary-8 .srt-text-tertiary,
.sr-bb .srt-primary-8.srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-1 .srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-1.srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-2 .srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-2.srt-text-tertiary,
.sr-bb .srt-primary-9 .srt-text-tertiary,
.sr-bb .srt-primary-9.srt-text-tertiary,
.sr-bb .srt-primary-10 .srt-text-tertiary,
.sr-bb .srt-primary-10.srt-text-tertiary,
.sr-bb .srt-primary-11 .srt-text-tertiary,
.sr-bb .srt-primary-11.srt-text-tertiary,
.sr-bb .srt-primary-12 .srt-text-tertiary,
.sr-bb .srt-primary-12.srt-text-tertiary,
.sr-bb .srt-primary-13 .srt-text-tertiary,
.sr-bb .srt-primary-13.srt-text-tertiary {
	opacity: 0.54;
}

.sr-bb .srt-icon {
	opacity: 0.33;
}

.sr-bb .srt-icon-secondary {
	opacity: 0.7;
}

.sr-bb .srt-elevation-1 {
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 20%), 0 1px 3px 0 rgba(0, 0, 0, 10%);
}

.sr-bb .srt-elevation-2 {
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 23%), 0 3px 6px 0 rgba(0, 0, 0, 16%);
}

.sr-bb .srt-elevation-3 {
	box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 26%), 0 10px 20px 0 rgba(0, 0, 0, 19%);
}

.sr-bb .srt-elevation-center-2 {
	box-shadow: 0 1px 5px rgba(0, 0, 0, 23%), 0 1px 5px rgba(0, 0, 0, 16%);
}

.sr-bb .srt-inset-top-1 {
	box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 23%);
}

.sr-bb .srt-inset-bottom-1 {
	box-shadow: inset 0 -1px 2px 0 rgba(0, 0, 0, 23%);
}

.sr-bb .srt-inset-top-2 {
	box-shadow: inset 0 3px 6px 0 rgba(0, 0, 0, 23%);
}

.sr-bb .srt-inset-bottom-2 {
	box-shadow: inset 0 -3px 6px 0 rgba(0, 0, 0, 23%);
}

.sr-bb .srt-inset-top-3 {
	box-shadow: inset 0 6px 6px 0 rgba(0, 0, 0, 23%);
}

.sr-bb .srt-inset-bottom-3 {
	box-shadow: inset 0 -6px 6px 0 rgba(0, 0, 0, 23%);
}

.sr-bb .srt-fill-blue-card {
	fill: #2579ad;
}

.sr-bb .srt-stroke-blue-card {
	stroke: #2579ad;
}

.sr-bb .srt-fill-green-card {
	fill: #2cbd00;
}

.sr-bb .srt-stroke-green-card {
	stroke: #2cbd00;
}

.sr-bb .srt-fill-soccer-yellow-card {
	fill: #ffbf00;
}

.sr-bb .srt-stroke-soccer-yellow-card {
	stroke: #ffbf00;
}

.sr-bb .srt-fill-soccer-red-card {
	fill: #e43b3b;
}

.sr-bb .srt-stroke-soccer-red-card {
	stroke: #e43b3b;
}

.sr-bb .srt-stroke-soccer-substitution-in {
	fill: transparent;
	stroke: #4fbe30;
}

.sr-bb .srt-fill-soccer-substitution-in {
	fill: #4fbe30;
}

.sr-bb .srt-stroke-soccer-substitution-out {
	fill: transparent;
	stroke: #e43b3b;
}

.sr-bb .srt-fill-soccer-substitution-out {
	fill: #e43b3b;
}

.sr-bb .srt-stroke-soccer-own-goal {
	fill: transparent;
	stroke: #e43b3b;
}

.sr-bb .srt-fill-soccer-own-goal {
	fill: #e43b3b;
}

.sr-bb .srt-fill-soccer-relegation-1 {
	fill: #fdd835;
}

.sr-bb .srt-stroke-soccer-relegation-1 {
	stroke: #fdd835;
}

.sr-bb .srt-fill-soccer-relegation-2 {
	fill: #ffb848;
}

.sr-bb .srt-stroke-soccer-relegation-2 {
	stroke: #ffb848;
}

.sr-bb .srt-fill-soccer-relegation-3 {
	fill: #ef6c00;
}

.sr-bb .srt-stroke-soccer-relegation-3 {
	stroke: #ef6c00;
}

.sr-bb .srt-fill-soccer-relegation-4 {
	fill: #e93a34;
}

.sr-bb .srt-stroke-soccer-relegation-4 {
	stroke: #e93a34;
}

.sr-bb .srt-fill-soccer-relegation-5 {
	fill: #941d1d;
}

.sr-bb .srt-stroke-soccer-relegation-5 {
	stroke: #941d1d;
}

.sr-bb .srt-fill-soccer-promotion-1 {
	fill: #51d151;
}

.sr-bb .srt-stroke-soccer-promotion-1 {
	stroke: #51d151;
}

.sr-bb .srt-fill-soccer-promotion-2 {
	fill: #1b911b;
}

.sr-bb .srt-stroke-soccer-promotion-2 {
	stroke: #1b911b;
}

.sr-bb .srt-fill-soccer-promotion-3 {
	fill: #0e8094;
}

.sr-bb .srt-stroke-soccer-promotion-3 {
	stroke: #0e8094;
}

.sr-bb .srt-fill-soccer-promotion-4 {
	fill: #0a6cce;
}

.sr-bb .srt-stroke-soccer-promotion-4 {
	stroke: #0a6cce;
}

.sr-bb .srt-fill-soccer-promotion-5 {
	fill: #4a9fe4;
}

.sr-bb .srt-stroke-soccer-promotion-5 {
	stroke: #4a9fe4;
}

.sr-bb .srt-nfl-timeout-1 {
	background-color: #f5a623;
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-nfl-timeout-2 {
	background-color: rgba(245, 166, 35, 30%);
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-nfl-penalty {
	background-color: #f4a621;
	border-color: #f4a621;
	color: #000;
}

.sr-bb .srt-nfl-negative-yards-1 {
	background-color: #ac182e;
	border-color: #ac182e;
	color: #000;
}

.sr-bb .srt-nfl-negative-yards-2 {
	background-color: #ac182e;
	border-color: #ac182e;
	color: #000;
}

.sr-bb .srt-nfl-first-line {
	background-color: #4b90de;
	border-color: #4b90de;
	color: #000;
}

.sr-bb .srt-nfl-ten-line {
	background-color: #f5a623;
	border-color: #f5a623;
	color: #000;
}

.sr-bb .srt-fill-nfl-penalty {
	fill: #f4a621;
}

.sr-bb .srt-stroke-nfl-penalty {
	stroke: #f4a621;
}

.sr-bb .srt-fill-nfl-negative-yards-1 {
	fill: #ac182e;
}

.sr-bb .srt-stroke-nfl-negative-yards-1 {
	stroke: #ac182e;
}

.sr-bb .srt-fill-nfl-negative-yards-2 {
	fill: #ac182e;
}

.sr-bb .srt-stroke-nfl-negative-yards-2 {
	stroke: #ac182e;
}

.sr-bb .srt-fill-nfl-first-line {
	fill: #4b90de;
}

.sr-bb .srt-stroke-nfl-first-line {
	stroke: #4b90de;
}

.sr-bb .srt-fill-nfl-ten-line {
	fill: #f5a623;
}

.sr-bb .srt-stroke-nfl-ten-line {
	stroke: #f5a623;
}

.sr-bb .srt-mlb-run-1 {
	background-color: #090;
	border-color: #090;
	color: #fff;
}

.sr-bb .srt-mlb-run-2 {
	background-color: #fff;
	border-color: #090;
	color: #090;
}

.sr-bb .srt-mlb-hit-1 {
	background-color: #f7ab11;
	border-color: #f7ab11;
	color: #000;
}

.sr-bb .srt-mlb-hit-2 {
	background-color: #fff;
	border-color: #f7ab11;
	color: #f7ab11;
}

.sr-bb .srt-mlb-hit-3 {
	background-color: #00a4ff;
	border-color: #00a4ff;
	color: #fff;
}

.sr-bb .srt-mlb-error-1 {
	background-color: #d0021b;
	border-color: #d0021b;
	color: #fff;
}

.sr-bb .srt-mlb-error-2 {
	background-color: #fff;
	border-color: #d0021b;
	color: #d0021b;
}

.sr-bb .srt-mlb-neutral-1 {
	background-color: #212121;
	border-color: #fff;
	color: #fff;
}

.sr-bb .srt-fill-mlb-base {
	fill: #00a4ff;
}

.sr-bb .srt-strole-mlb-base {
	fill: #00a4ff;
}

.sr-bb .srt-fill-change-increase {
	fill: #ff993d;
}

.sr-bb .srt-stroke-change-increase {
	stroke: #ff993d;
}

.sr-bb .srt-fill-change-decrease {
	fill: #e26591;
}

.sr-bb .srt-stroke-change-decrease {
	stroke: #e26591;
}

.sr-bb .srt-fill-text {
	fill: #000;
}

.sr-bb .srt-fill-text-invert {
	fill: #000;
}

.sr-bb .srt-fill-text-secondary {
	fill-opacity: 0.7;
}

.sr-bb .srt-fill-text-disabled {
	fill-opacity: 0.35;
}

.sr-bb .srt-fill-base-1 {
	fill: transparent;
}

.sr-bb .srt-stroke-base-1 {
	stroke: transparent;
}

.sr-bb .srt-fill-base-1-active {
	fill: rgba(255, 138, 179, 12%);
}

.sr-bb .srt-stroke-base-1-active {
	stroke: rgba(255, 138, 179, 12%);
}

.sr-bb .srt-fill-base-1-active-2 {
	fill: #ddd;
}

.sr-bb .srt-stroke-base-1-active-2 {
	stroke: #ddd;
}

.sr-bb .srt-fill-base-1-primary {
	fill: transparent;
}

.sr-bb .srt-stroke-base-1-primary {
	stroke: transparent;
}

.sr-bb .srt-fill-base-1-home {
	fill: transparent;
}

.sr-bb .srt-stroke-base-1-home {
	stroke: transparent;
}

.sr-bb .srt-fill-base-1-away {
	fill: transparent;
}

.sr-bb .srt-stroke-base-1-away {
	stroke: transparent;
}

.sr-bb .srt-fill-base-2 {
	fill: currentcolor;
}

.sr-bb .srt-stroke-base-2 {
	stroke: currentcolor;
}

.sr-bb .srt-fill-base-2-active {
	fill: #f6e8ed;
}

.sr-bb .srt-stroke-base-2-active {
	stroke: #f6e8ed;
}

.sr-bb .srt-fill-base-2-hover {
	fill: #f6e8ed;
}

.sr-bb .srt-stroke-base-2-hover {
	stroke: #f6e8ed;
}

.sr-bb .srt-fill-base-3 {
	fill: #f6e8ed;
}

.sr-bb .srt-stroke-base-3 {
	stroke: #f6e8ed;
}

.sr-bb .srt-fill-base-3-active {
	fill: #f7e3ea;
}

.sr-bb .srt-stroke-base-3-active {
	stroke: #f7e3ea;
}

.sr-bb .srt-fill-base-3-hover {
	fill: #f7e3ea;
}

.sr-bb .srt-stroke-base-3-hover {
	stroke: #f7e3ea;
}

.sr-bb .srt-fill-primary-1 {
	fill: #ff8ab3;
}

.sr-bb .srt-stroke-primary-1 {
	stroke: #ff8ab3;
}

.sr-bb .srt-fill-primary-2 {
	fill: #b3617d;
}

.sr-bb .srt-stroke-primary-2 {
	stroke: #b3617d;
}

.sr-bb .srt-fill-primary-3 {
	fill: #e67ca1;
}

.sr-bb .srt-stroke-primary-3 {
	stroke: #e67ca1;
}

.sr-bb .srt-fill-primary-4 {
	fill: #fbb5cd;
}

.sr-bb .srt-stroke-primary-4 {
	stroke: #fbb5cd;
}

.sr-bb .srt-fill-primary-5 {
	fill: #fbb5cd;
}

.sr-bb .srt-stroke-primary-5 {
	stroke: #fbb5cd;
}

.sr-bb .srt-fill-primary-6 {
	fill: #945068;
}

.sr-bb .srt-stroke-primary-6 {
	stroke: #945068;
}

.sr-bb .srt-fill-primary-7 {
	fill: #ff8ab3;
}

.sr-bb .srt-stroke-primary-7 {
	stroke: #ff8ab3;
}

.sr-bb .srt-fill-primary-8 {
	fill: #ff8ab3;
}

.sr-bb .srt-stroke-primary-8 {
	stroke: #ff8ab3;
}

.sr-bb .srt-fill-primary-8-is-active-1 {
	fill: #e67ca1;
}

.sr-bb .srt-stroke-primary-8-is-active-1 {
	stroke: #e67ca1;
}

.sr-bb .srt-fill-primary-8-is-active-2 {
	fill: #e67ca1;
}

.sr-bb .srt-stroke-primary-8-is-active-2 {
	stroke: #e67ca1;
}

.sr-bb .srt-fill-primary-9 {
	fill: #f8d5e1;
}

.sr-bb .srt-stroke-primary-9 {
	stroke: #f8d5e1;
}

.sr-bb .srt-fill-primary-10 {
	fill: #fd9fc0;
}

.sr-bb .srt-stroke-primary-10 {
	stroke: #fd9fc0;
}

.sr-bb .srt-fill-primary-11 {
	fill: #e67ca1;
}

.sr-bb .srt-stroke-primary-11 {
	stroke: #e67ca1;
}

.sr-bb .srt-fill-primary-12 {
	fill: #ff8ab3;
}

.sr-bb .srt-stroke-primary-12 {
	stroke: #ff8ab3;
}

.sr-bb .srt-fill-home-1 {
	fill: #ff993d;
}

.sr-bb .srt-stroke-home-1 {
	stroke: #ff993d;
}

.sr-bb .srt-fill-home-2 {
	fill: #ff993d;
}

.sr-bb .srt-stroke-home-2 {
	stroke: #ff993d;
}

.sr-bb .srt-fill-home-3 {
	fill: #ff993d;
}

.sr-bb .srt-stroke-home-3 {
	stroke: #ff993d;
}

.sr-bb .srt-fill-home-4 {
	fill: #fcb574;
}

.sr-bb .srt-stroke-home-4 {
	stroke: #fcb574;
}

.sr-bb .srt-fill-home-5 {
	fill: #a66328;
}

.sr-bb .srt-stroke-home-5 {
	stroke: #a66328;
}

.sr-bb .srt-fill-away-1 {
	fill: #e26591;
}

.sr-bb .srt-stroke-away-1 {
	stroke: #e26591;
}

.sr-bb .srt-fill-away-2 {
	fill: #e26591;
}

.sr-bb .srt-stroke-away-2 {
	stroke: #e26591;
}

.sr-bb .srt-fill-away-3 {
	fill: #e26591;
}

.sr-bb .srt-stroke-away-3 {
	stroke: #e26591;
}

.sr-bb .srt-fill-away-4 {
	fill: #e890af;
}

.sr-bb .srt-stroke-away-4 {
	stroke: #e890af;
}

.sr-bb .srt-fill-away-5 {
	fill: #93425e;
}

.sr-bb .srt-stroke-away-5 {
	stroke: #93425e;
}

.sr-bb .srt-fill-neutral-1 {
	fill: #d5d5d5;
}

.sr-bb .srt-stroke-neutral-1 {
	stroke: #d5d5d5;
}

.sr-bb .srt-fill-neutral-2 {
	fill: #191919;
}

.sr-bb .srt-stroke-neutral-2 {
	stroke: #191919;
}

.sr-bb .srt-fill-neutral-3 {
	fill: rgba(0, 0, 0, 12%);
}

.sr-bb .srt-stroke-neutral-3 {
	stroke: rgba(0, 0, 0, 12%);
}

.sr-bb .srt-fill-neutral-4 {
	fill: #565656;
}

.sr-bb .srt-stroke-neutral-4 {
	stroke: #565656;
}

.sr-bb .srt-fill-neutral-5 {
	fill: #3d3d3d;
}

.sr-bb .srt-stroke-neutral-5 {
	stroke: #3d3d3d;
}

.sr-bb .srt-fill-neutral-6 {
	fill: #bababa;
}

.sr-bb .srt-stroke-neutral-6 {
	stroke: #bababa;
}

.sr-bb .srt-fill-neutral-7 {
	fill: #565656;
}

.sr-bb .srt-stroke-neutral-7 {
	stroke: #565656;
}

.sr-bb .srt-fill-neutral-8 {
	fill: #d5d5d5;
}

.sr-bb .srt-stroke-neutral-8 {
	stroke: #d5d5d5;
}

.sr-bb .srt-fill-neutral-9 {
	fill: #e6e6e6;
}

.sr-bb .srt-stroke-neutral-9 {
	stroke: #e6e6e6;
}

.sr-bb .srt-fill-neutral-10 {
	fill: #d8d8d8;
}

.sr-bb .srt-stroke-neutral-10 {
	stroke: #d8d8d8;
}

.sr-bb .srt-fill-neutral-11 {
	fill: #939393;
}

.sr-bb .srt-stroke-neutral-11 {
	stroke: #939393;
}

.sr-bb .srt-fill-neutral-12 {
	fill: #4a4a4a;
}

.sr-bb .srt-stroke-neutral-12 {
	stroke: #4a4a4a;
}

.sr-bb .srt-fill-neutral-13 {
	fill: #ddd;
}

.sr-bb .srt-stroke-neutral-13 {
	stroke: #ddd;
}

.sr-bb .srt-fill-win {
	fill: #ff993d;
}

.sr-bb .srt-stroke-win {
	stroke: #ff993d;
}

.sr-bb .srt-fill-draw {
	fill: rgba(0, 0, 0, 40%);
}

.sr-bb .srt-stroke-draw {
	stroke: rgba(0, 0, 0, 40%);
}

.sr-bb .srt-fill-lose {
	fill: #e26591;
}

.sr-bb .srt-stroke-lose {
	stroke: #e26591;
}

.sr-bb .srt-stop-base-1 {
	stop-color: transparent;
}

.sr-bb .srt-stop-primary-1 {
	stop-color: #ff8ab3;
}

.sr-bb .srt-stop-primary-2 {
	stop-color: #b3617d;
}

.sr-bb .srt-stop-primary-3 {
	stop-color: #e67ca1;
}

.sr-bb .srt-stop-primary-4 {
	stop-color: #fbb5cd;
}

.sr-bb .srt-stop-primary-5 {
	stop-color: #fbb5cd;
}

.sr-bb .srt-stop-primary-6 {
	stop-color: #945068;
}

.sr-bb .srt-stop-primary-7 {
	stop-color: #ff8ab3;
}

.sr-bb .srt-stop-primary-8 {
	stop-color: #ff8ab3;
}

.sr-bb .srt-stop-primary-9 {
	stop-color: #f8d5e1;
}

.sr-bb .srt-stop-primary-10 {
	stop-color: #fd9fc0;
}

.sr-bb .srt-stop-primary-11 {
	stop-color: #e67ca1;
}

.sr-bb .srt-stop-primary-12 {
	stop-color: #ff8ab3;
}

.sr-bb .srt-stop-home-1 {
	stop-color: #ff993d;
}

.sr-bb .srt-stop-away-1 {
	stop-color: #e26591;
}

.sr-bb .srt-fill-neutral-14 {
	fill: #f5f5f5;
}

.sr-bb .srt-stroke-neutral-14 {
	stroke: #f5f5f5;
}

.sr-bb .srt-logo-powered-by-light {
	display: inline-block;
}

.sr-bb .srt-logo-powered-by-dark {
	display: none;
}
